import { render, staticRenderFns } from "./RestaurantOrderAlpha.vue?vue&type=template&id=37f88009&scoped=true&"
import script from "./RestaurantOrderAlpha.vue?vue&type=script&lang=js&"
export * from "./RestaurantOrderAlpha.vue?vue&type=script&lang=js&"
import style0 from "./RestaurantOrderAlpha.vue?vue&type=style&index=0&id=37f88009&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f88009",
  null
  
)

export default component.exports