import { render, staticRenderFns } from "./MenuDelete.vue?vue&type=template&id=701e7bd3&scoped=true&"
import script from "./MenuDelete.vue?vue&type=script&lang=js&"
export * from "./MenuDelete.vue?vue&type=script&lang=js&"
import style0 from "./MenuDelete.vue?vue&type=style&index=0&id=701e7bd3&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "701e7bd3",
  null
  
)

export default component.exports